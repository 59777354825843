*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --projectwidth: 810px;
  margin:0;
  padding:0;

    //-webkit-transform: translate3d(0,0,0);
    background: linear-gradient(-45deg, rgb(0,0,20), rgb(20,20,20));
    background-color: #000;

}
@media only screen and (max-width: 1700px) {
  :root {
    --projectwidth: 550px;


  }
  .grid{
     padding-left:20px;
  }
}

@media only screen and (max-width: 1000px) {
  :root {
    --projectwidth: 610px;
    margin:0;
    padding:0;


  }

  .grid{
     padding-left:150px;
  }

  .mainMenu {


    margin-left:130px !important;

   }





}
@media only screen and (max-width: 800px) {
  :root {
    --projectwidth: 270px;


  }
  .grid{
     padding-left:0px;
  }
  .mainMenu {
    width:1000px !important;
      margin-left:0px !important;

    font-size:18px !important;

   }

   .mainMenu li {
   	display:inline-block;


     padding:10px 50px 10px 50px !important;


   }

   .headline{

      margin-left:-35px !important;

   }


}


@media only screen and (max-width: 500px) {
  :root {
    --projectwidth: 270px;

  }

  .fullOverlay{
    position: absolute;
     width:115% !important;
     height:140% !important;

  }

  .griditem{

    margin:50px !important;



  }


  .grid img{
    width:300px !important;
    //border-radius: 5px;
  }

  .mainMenu {


    display:none !important;

   }

   .navbarbg{

     width:120% !important;

   }
}


body1{
  background-color: rgb(10,10,10);
  color: rgb(250,250,250);
  background-color: #FD940A;
  background-image: radial-gradient(circle, #F9A72B 0%, #FA9026 70%, #FB6C1F 100%);
}


body4 {
	//background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background: linear-gradient(-45deg, rgb(0,0,20), rgb(20,20,20));
	background-size: 100% 100%;
	//animation: gradient 25s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.hideScroll{
    overflow-y: hidden;
}

.showScroll{
    overflow-y: auto;
}

.navbarbg{
  position: absolute;
  width:100%;
  height:66px;
  background-color: #3498db;
  z-index:10;
}
.mainMenu {
  position: absolute;
  margin:10px 30px -10px 0;

	font-family: sans-serif;
left:100px;

  font-size:20px;
  display: inline-block;
  width:calc(var(--projectwidth)*2) !important;
  text-align: left;
  width: min-content;
  z-index: 20;




}
.mainMenu li {
	display:inline-block;

	float:left;
	margin-left:-70px;
  cursor:pointer;
  box-sizing: none;
  margin-right:20px;
  padding:10px 60px 10px 60px;
  border-radius: 100px;
  color:#2B7DAC;
  color:#FFF;

}



.mainMenu li:hover {
background-color: rgba(255, 0, 166, .25);
//background-color: #2B7DAC;
	color:#FFF;
}

.reset{
  position: absolute;
  font-size:40px;
  color:#FFF;
  z-index:20;
  margin:5px 100px 10px 10px;
  cursor: pointer;
  width:40px;
  opacity:.5;



  border-radius: 40px;
}

.reset:hover {
background-color: rgba(255, 0, 166, .25);
opacity:1;
}

.fullOverlay{
  position: absolute;
   width:100%;
   height:100%;
   z-index: 100;
   background-color: rgb(255,255,255);
   background-color: rgb(0,0,0);
   padding:0;
   margin:0;

}

.fullOverlay[animate='1']{
/*transition:3s ease;*/

   animation: casestudy .5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

}

@keyframes casestudy {
  from {
    opacity:0;
    transform: scale(.5);
  }
  to {

    opacity:1;
    transform: scale(1);
  }
}

.close{
  position: fixed;
  right:10px;
   cursor: pointer;
   font-size: 30px;
   color:rgb(0,0,0);
   width:50px;
   height:50px;
   z-index: 200;

}

.casestudy{
  width:100%;
  height:100%;
  position:absolute;
  top:0px;
}
.gridholder{
  display: inline-block;
  grid-template-columns: auto auto auto;

  text-align: center;
  box-sizing: border-box;
  width:100%;
}
.grid{

  margin:40px 0 150px 0;
  display: inline-block;
  width:calc(var(--projectwidth)*2) !important;
  text-align: left;
  width: min-content;



}

.griditem{
  display:inline-block;

  text-align: left;
  padding:0px;
  cursor:pointer;
  border:1px solid white;
  margin:15px 15px 18px 15px;



    transition: transform 500ms;

    background-color: #000;

    //color:#2B7DAC;


    animation: intro 1s;
    animation-timing-function: ease-out;





}

@keyframes intro {
  from {
    opacity:0;
    transform: scale(.25) translateY(-2200px);

  }
  to {

    opacity:1;
    transform: scale(1) translateY(0px);

  }
}

.griditem:hover{

  //-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  //filter: grayscale(0%);
  -webkit-filter: saturate(120%);
  filter: saturate(120%);
  transition: transform 250ms;
  transform: scale(1.1);
  transition-timing-function: ease-out;
}



.grid img{
  width:500px;
  //border-radius: 5px;
}

.headline{
  font-size:40px;
  padding:45px 0 20px 50px;
   text-transform: capitalize;
   color:#114766;
   margin-left:-100px;

}
.title{
  font-size: 16px;
  color:#FFF;
  padding:10px 5px 0 10px;
}
.cat{

  font-size:14px;
  color:#999;
  color:#2B7DAC;
  padding:10px 5px 10px 10px;
}
